
jQuery(function() {
    var webStorage = function () {
        if (sessionStorage.getItem('access')) {
        /*2回目以降アクセス時の処理*/
        setTimeout(function () {
            // ローディングを数秒後に非表示にする
            $('#loader-bg').fadeOut(400);
        }, 1000);
        } else {
        /*初回アクセス時の処理*/
        sessionStorage.setItem('access', 'true'); // sessionStorageにデータを保存
        //   ("#loader-bg").addClass('is-active');
        setTimeout(function () {
            // ローディングを数秒後に非表示にする
            $('#loader-bg').fadeOut(600);
        }, 1800); // ローディングを表示する時間
        }
    }
    webStorage();
    
    $('.c-drawer-icon').on('click', function(e) {
        e.preventDefault();
    
        jQuery('.c-drawer-icon').toggleClass('is-active');
        jQuery('.c-drawer').toggleClass('is-active');
        jQuery('.c-drawer-background').toggleClass('is-active');
    
        return false;
    });

    $('.faq__q').on('click', function(){
        $(this).next().slideToggle();
        $(this).children('.faq__icon').toggleClass('open')
    });

    $('.tab-item').click(function(){
        var index = $('.tab-item').index(this);
        $('.tab-item').removeClass('active');
        $('.tab-text').removeClass('active');
        $(this).addClass('active');
        $('.tab-text').eq(index).addClass('active');
    });
});